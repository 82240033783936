exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-a-propos-tsx": () => import("./../../../src/pages/aPropos.tsx" /* webpackChunkName: "component---src-pages-a-propos-tsx" */),
  "component---src-pages-activites-tsx": () => import("./../../../src/pages/activites.tsx" /* webpackChunkName: "component---src-pages-activites-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ressources-decouvrir-tsx": () => import("./../../../src/pages/ressources/decouvrir.tsx" /* webpackChunkName: "component---src-pages-ressources-decouvrir-tsx" */),
  "component---src-pages-ressources-documents-tsx": () => import("./../../../src/pages/ressources/documents.tsx" /* webpackChunkName: "component---src-pages-ressources-documents-tsx" */),
  "component---src-pages-ressources-lectures-tsx": () => import("./../../../src/pages/ressources/lectures.tsx" /* webpackChunkName: "component---src-pages-ressources-lectures-tsx" */),
  "component---src-pages-ressources-lecturescommentees-tsx": () => import("./../../../src/pages/ressources/lecturescommentees.tsx" /* webpackChunkName: "component---src-pages-ressources-lecturescommentees-tsx" */),
  "component---src-pages-ressources-liens-tsx": () => import("./../../../src/pages/ressources/liens.tsx" /* webpackChunkName: "component---src-pages-ressources-liens-tsx" */),
  "component---src-pages-ressources-tsx": () => import("./../../../src/pages/ressources.tsx" /* webpackChunkName: "component---src-pages-ressources-tsx" */),
  "component---src-pages-tradition-autres-maitres-dalailama-tsx": () => import("./../../../src/pages/tradition/autres-maitres/dalailama.tsx" /* webpackChunkName: "component---src-pages-tradition-autres-maitres-dalailama-tsx" */),
  "component---src-pages-tradition-autres-maitres-dudjomsangyepemashepa-tsx": () => import("./../../../src/pages/tradition/autres-maitres/dudjomsangyepemashepa.tsx" /* webpackChunkName: "component---src-pages-tradition-autres-maitres-dudjomsangyepemashepa-tsx" */),
  "component---src-pages-tradition-autres-maitres-dzakilungrinpoche-tsx": () => import("./../../../src/pages/tradition/autres-maitres/dzakilungrinpoche.tsx" /* webpackChunkName: "component---src-pages-tradition-autres-maitres-dzakilungrinpoche-tsx" */),
  "component---src-pages-tradition-autres-maitres-dzigarkongtrulrinpoche-tsx": () => import("./../../../src/pages/tradition/autres-maitres/dzigarkongtrulrinpoche.tsx" /* webpackChunkName: "component---src-pages-tradition-autres-maitres-dzigarkongtrulrinpoche-tsx" */),
  "component---src-pages-tradition-autres-maitres-tralegkyabgonrinpoche-tsx": () => import("./../../../src/pages/tradition/autres-maitres/tralegkyabgonrinpoche.tsx" /* webpackChunkName: "component---src-pages-tradition-autres-maitres-tralegkyabgonrinpoche-tsx" */),
  "component---src-pages-tradition-autres-sources-autressources-tsx": () => import("./../../../src/pages/tradition/autres-sources/autressources.tsx" /* webpackChunkName: "component---src-pages-tradition-autres-sources-autressources-tsx" */),
  "component---src-pages-tradition-bouddhas-fondateurs-bouddhashakyamuni-tsx": () => import("./../../../src/pages/tradition/bouddhas-fondateurs/bouddhashakyamuni.tsx" /* webpackChunkName: "component---src-pages-tradition-bouddhas-fondateurs-bouddhashakyamuni-tsx" */),
  "component---src-pages-tradition-bouddhas-fondateurs-gururinpoche-tsx": () => import("./../../../src/pages/tradition/bouddhas-fondateurs/gururinpoche.tsx" /* webpackChunkName: "component---src-pages-tradition-bouddhas-fondateurs-gururinpoche-tsx" */),
  "component---src-pages-tradition-bouddhas-fondateurs-yeshetsogyal-tsx": () => import("./../../../src/pages/tradition/bouddhas-fondateurs/yeshetsogyal.tsx" /* webpackChunkName: "component---src-pages-tradition-bouddhas-fondateurs-yeshetsogyal-tsx" */),
  "component---src-pages-tradition-coordination-locale-vincentthibault-tsx": () => import("./../../../src/pages/tradition/coordination-locale/vincentthibault.tsx" /* webpackChunkName: "component---src-pages-tradition-coordination-locale-vincentthibault-tsx" */),
  "component---src-pages-tradition-figures-majeures-dudjomlingpa-tsx": () => import("./../../../src/pages/tradition/figures-majeures/dudjomlingpa.tsx" /* webpackChunkName: "component---src-pages-tradition-figures-majeures-dudjomlingpa-tsx" */),
  "component---src-pages-tradition-figures-majeures-jamgonmiphamrinpoche-tsx": () => import("./../../../src/pages/tradition/figures-majeures/jamgönmiphamrinpoche.tsx" /* webpackChunkName: "component---src-pages-tradition-figures-majeures-jamgonmiphamrinpoche-tsx" */),
  "component---src-pages-tradition-figures-majeures-kunkhyenjigmelingpa-tsx": () => import("./../../../src/pages/tradition/figures-majeures/kunkhyenjigmelingpa.tsx" /* webpackChunkName: "component---src-pages-tradition-figures-majeures-kunkhyenjigmelingpa-tsx" */),
  "component---src-pages-tradition-figures-majeures-kunkhyenlongchenpa-tsx": () => import("./../../../src/pages/tradition/figures-majeures/kunkhyenlongchenpa.tsx" /* webpackChunkName: "component---src-pages-tradition-figures-majeures-kunkhyenlongchenpa-tsx" */),
  "component---src-pages-tradition-figures-majeures-patrulrinpoche-tsx": () => import("./../../../src/pages/tradition/figures-majeures/patrulrinpoche.tsx" /* webpackChunkName: "component---src-pages-tradition-figures-majeures-patrulrinpoche-tsx" */),
  "component---src-pages-tradition-figures-majeures-serakhandro-tsx": () => import("./../../../src/pages/tradition/figures-majeures/serakhandro.tsx" /* webpackChunkName: "component---src-pages-tradition-figures-majeures-serakhandro-tsx" */),
  "component---src-pages-tradition-invites-gregseton-tsx": () => import("./../../../src/pages/tradition/invites/gregseton.tsx" /* webpackChunkName: "component---src-pages-tradition-invites-gregseton-tsx" */),
  "component---src-pages-tradition-invites-khenposonam-tsx": () => import("./../../../src/pages/tradition/invites/khenposonam.tsx" /* webpackChunkName: "component---src-pages-tradition-invites-khenposonam-tsx" */),
  "component---src-pages-tradition-invites-orgyenchowangrinpoche-tsx": () => import("./../../../src/pages/tradition/invites/orgyenchowangrinpoche.tsx" /* webpackChunkName: "component---src-pages-tradition-invites-orgyenchowangrinpoche-tsx" */),
  "component---src-pages-tradition-maitres-de-nos-maitres-dungsethinleynorburinpoche-tsx": () => import("./../../../src/pages/tradition/maitres-de-nos-maitres/dungsethinleynorburinpoche.tsx" /* webpackChunkName: "component---src-pages-tradition-maitres-de-nos-maitres-dungsethinleynorburinpoche-tsx" */),
  "component---src-pages-tradition-maitres-de-nos-maitres-kyabjedilgokhyentserinpoche-tsx": () => import("./../../../src/pages/tradition/maitres-de-nos-maitres/kyabjedilgokhyentserinpoche.tsx" /* webpackChunkName: "component---src-pages-tradition-maitres-de-nos-maitres-kyabjedilgokhyentserinpoche-tsx" */),
  "component---src-pages-tradition-maitres-de-nos-maitres-kyabjedodrupchenrinpoche-tsx": () => import("./../../../src/pages/tradition/maitres-de-nos-maitres/kyabjedodrupchenrinpoche.tsx" /* webpackChunkName: "component---src-pages-tradition-maitres-de-nos-maitres-kyabjedodrupchenrinpoche-tsx" */),
  "component---src-pages-tradition-maitres-de-nos-maitres-kyabjedudjomrinpoche-tsx": () => import("./../../../src/pages/tradition/maitres-de-nos-maitres/kyabjedudjomrinpoche.tsx" /* webpackChunkName: "component---src-pages-tradition-maitres-de-nos-maitres-kyabjedudjomrinpoche-tsx" */),
  "component---src-pages-tradition-maitres-de-nos-maitres-lamatharchinrinpoche-tsx": () => import("./../../../src/pages/tradition/maitres-de-nos-maitres/lamatharchinrinpoche.tsx" /* webpackChunkName: "component---src-pages-tradition-maitres-de-nos-maitres-lamatharchinrinpoche-tsx" */),
  "component---src-pages-tradition-maitres-de-nos-maitres-vidyadharachogyamtrungparinpoche-tsx": () => import("./../../../src/pages/tradition/maitres-de-nos-maitres/vidyadharachogyamtrungparinpoche.tsx" /* webpackChunkName: "component---src-pages-tradition-maitres-de-nos-maitres-vidyadharachogyamtrungparinpoche-tsx" */),
  "component---src-pages-tradition-sources-directes-lamasonamtseringrinpoche-tsx": () => import("./../../../src/pages/tradition/sources-directes/lamasonamtseringrinpoche.tsx" /* webpackChunkName: "component---src-pages-tradition-sources-directes-lamasonamtseringrinpoche-tsx" */),
  "component---src-pages-tradition-sources-directes-samuelbercholz-tsx": () => import("./../../../src/pages/tradition/sources-directes/samuelbercholz.tsx" /* webpackChunkName: "component---src-pages-tradition-sources-directes-samuelbercholz-tsx" */),
  "component---src-pages-tradition-sources-directes-tulkuthonduprinpoche-tsx": () => import("./../../../src/pages/tradition/sources-directes/tulkuthonduprinpoche.tsx" /* webpackChunkName: "component---src-pages-tradition-sources-directes-tulkuthonduprinpoche-tsx" */),
  "component---src-pages-tradition-tsx": () => import("./../../../src/pages/tradition.tsx" /* webpackChunkName: "component---src-pages-tradition-tsx" */)
}

